<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="org">
      <v-card-title class="headline">Integrations for {{org.name}}</v-card-title>

      <v-card-text>      
        <p>
          Advanced integration options to configure and automate interactions with your organization.
        </p>

        <v-alert v-if="!orgUtil.isProPlan()" type="info" outlined>
          <p><router-link class="info--text" :to="{name: 'orgmanagerSubscription', params: {id:org.id}}">Upgrade to a PRO plan</router-link> to be able to embed results or connect with our API.</p>
        </v-alert>
      </v-card-text>

      <v-card-title class="subheader">API access</v-card-title>
      <v-card-text>      
        <p>
          Use our API to integrate directly with the platform. This allows you to automatically sync registration data from your website with our platform. 
          <a href="/api-docs" target="_blank">View API docs</a>.
        </p>
        <p>
          Use these API key(s) to connect to this organization using our API. Keep these API keys secret and never expose them publicly.
        </p>
        <code v-if="orgUtil.isProPlan()">{{ (org.api_keys||[]).join(', ') }}</code>
        <code v-else>Upgrade to PRO to access the API.</code>
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import OrgUtil from "@/util/orgUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Org",
  components: {
  },
  props: {
    org: Object,
  },
  data() {
    return {
      tenant: tenant,
    };
  },
  async mounted() {
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {
    async getGroup(id) {
    },

    async getData() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
      }
    },

  },
  computed: {
    
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: 'Integrations', disabled: true },
      ];
    },
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    ...mapGetters({
      user: "user"
    })
  },
  
};
</script>

